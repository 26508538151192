export const getURLImage = (tipo, nivel) => {
  if (tipo !== "Via Liberada") {
    if (nivel === "Alagamento")
      return "https://transito.poa.appdigital.tec.br/img/alertas/acumulo_de_agua.jpeg";
    if (nivel === "Sinistro de Trânsito")
      return "https://transito.poa.appdigital.tec.br/img/alertas/sinistro_de_transito.jpeg";
    if (nivel === "Obras na via")
      return "https://transito.poa.appdigital.tec.br/img/alertas/obra_na_via.jpeg";
    if (nivel === "Evento")
      return "https://transito.poa.appdigital.tec.br/img/alertas/evento_cultural.jpg";
    if (nivel === "Manifestação")
      return "https://transito.poa.appdigital.tec.br/img/alertas/passeatas.jpeg";
    if (nivel === "Bloqueio Ciclovia")
      return "https://transito.poa.appdigital.tec.br/img/alertas/bloqueio_ciclovia.jpeg";
    if (nivel === "Queda de árvore")
      return "https://transito.poa.appdigital.tec.br/img/alertas/queda_de_arvore.jpeg";
    if (nivel === "Desvio do Transporte Público")
      return (
        "https://transito.poa.appdigital.tec.br/img/alertas/desvio_transporte_publico.jpeg"
      );
    if (nivel === "Desvio de Transito")
      return "https://transito.poa.appdigital.tec.br/img/alertas/desvio_transito.jpeg";
    if (nivel === "Lentidão na Via")
      return "https://transito.poa.appdigital.tec.br/img/alertas/lentidao_na_via.jpg";
    if (nivel === "Obstrução na via")
      return "https://transito.poa.appdigital.tec.br/img/alertas/obstrucao_na_via.jpeg";
  }
  return "https://transito.poa.appdigital.tec.br/img/alertas/via_liberada.jpeg";
};
