import api from "@/services/api";
import { getURLImage } from "@/utils/getUrlImage.js";

const alerta = {
  namespaced: true,
  state: {
    tipo: {
      options: [
        { option: "Interdição Parcial" },
        { option: "Interdição Total" },
        { option: "Via Liberada" },
        { option: "EPTC informa:" },
      ],
      selected: "Parcial",
    },
    previsao_liberacao: " ",
    nivel_evento: {
      options: [
        { option: "Alagamento" },
        { option: "Sinistro de Trânsito" },
        { option: "Obras na via" },
        { option: "Evento" },
        { option: "Manifestação" },
        { option: "Bloqueio Ciclovia" },
        { option: "Queda de árvore" },
        { option: "Desvio do Transporte Público" },
        { option: "Desvio de Transito" },
        { option: "Lentidão na Via" },
        { option: "Obstrução na via" },
      ],
      selected: "Alagamento",
      oldValue: "Alagamento",
    },
    via: "",
    sentido: "",
    informa_trecho: {
      selected: false,
      trecho_one: "",
      trecho_two: "",
    },
    mensagem: "",
    disabed: false,
  },
  getters: {
    getterTipo: (state) => state.tipo,
    getterPrevisao: (state) => state.previsao_liberacao,
    getterNivel: (state) => state.nivel_evento,
    getterVia: (state) => state.via,
    getterSentido: (state) => state.sentido,
    getterInformaTrecho: (state) => state.informa_trecho,
    getterMensagem: (state) => state.mensagem,
    getterDisabled: (state) => state.disabed,
  },
  mutations: {
    CHANGE_SELECTED_TIPO: (state, value) => {
      state.tipo.selected = value.target.value;
    },
    CHANGE_SELECTED_PREVISAO: (state, value) => {
      state.previsao_liberacao = value.target.value;
    },
    CHANGE_SELECTED_NIVEL: (state, value) => {
      state.nivel_evento.selected = value.target.value;
    },
    CHANGE_INPUT_VIA: (state, value) => {
      state.via = value.target.value;
    },
    CHANGE_INPUT_SENTIDO: (state, value) => {
      state.sentido = value.target.value;
    },
    CHANGE_SELECTED_INFORMA_TRECHO: (state, value) => {
      state.informa_trecho.selected = value;
    },
    CHANGE_SELECTED_INFORMA_TRECHO_ONE: (state, value) => {
      state.informa_trecho.trecho_one = value.target.value;
    },
    CHANGE_SELECTED_INFORMA_TRECHO_TWO: (state, value) => {
      state.informa_trecho.trecho_two = value.target.value;
    },
  },
  actions: {
    async send({ commit, state }) {
      if (!state.via) return alert("Campo 'via' obrigatório.");
      if (!state.previsao_liberacao) return alert("Campo 'Previsão' obrigatório.");
      if (!state.sentido) return alert("Campo 'sentido' obrigatório.");
      if (state.informa_trecho.selected)
        if (
          !state.informa_trecho.trecho_one ||
          !state.informa_trecho.trecho_two
        )
          return alert("Campo 'Trecho 1' e 'Trecho 2' obrigatório.");

      try {
        state.disabed = true;
        await api.post("", {
          tipo: state.tipo.selected,
          previsao_liberacao: state.previsao_liberacao,
          nivel_evento: {
            evento: state.nivel_evento.selected,
            urlImage: getURLImage(
              state.tipo.selected,
              state.nivel_evento.selected
            ),
          },
          via: state.via, // Campo aberto para digitação
          sentido: state.sentido,
          trecho: {
            informar: state.informa_trecho.selected,
            trecho_one: state.informa_trecho.trecho_one ?? "",
            trecho_two: state.informa_trecho.trecho_two ?? "",
          },
        });
        state.mensagem = "Alerta enviado com sucesso!";
        setTimeout(() => {
          state.mensagem = "";
          location.reload();
        }, 8000);
        clearTimeout();
        state.disabed = false;
        window.scrollTo({ top: 0 });
      } catch (error) {
        console.log(error);
        state.mensagem = "Algo aconteceu";
        // setTimeout(() => {
        //   state.mensagem = "";
        //   location.reload();
        // }, 20000);
        // clearTimeout();
        // state.disabed = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
  },
};

export default alerta;
